import React from 'react'

import isv from '../img/isv.png'
import Regstrion from './Regstrion'

function Investor() {
    return (
        <div>
            <div className='container'>
                <div className='row pt-5 pb-5 '>
                    <div className='col-sm-12'>
                        <h2 className='h2-color text-center'>Gold Token Registration</h2>
                        <img src={isv} style={{ width: '100%' }} alt='' />
                    </div>
                </div>
            </div>
            <Regstrion />
        </div>
    )
}

export default Investor