import React, { useState, useEffect } from 'react'
import {
  Card, Breadcrumbs, Typography, IconButton, ButtonGroup
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { FaEthereum } from "react-icons/fa6";
import { Input, InputGroup, Button, List } from 'reactstrap';
import axios from 'axios';

function WalletAddress() {
  const [newAddress, setNewAddress] = useState('');
  const [userData, setUser] = useState('');
  const [addressList, setAddresList] = useState([])

  const addAddress = async () => {
    console.log(userData)
    if (newAddress !== '') {
      const resData = await axios.post(`${process.env.REACT_APP_API_URL}/wallet/add`, { user_id: userData.id, address: newAddress })
      console.log(resData)
      setNewAddress('')
    }
  }
  const fetchList = async () => {
    const resData = await axios.get(`${process.env.REACT_APP_API_URL}/wallet/list`)
    if (resData.data) {
      setAddresList(resData.data.result)
    }
  }
  useEffect(() => {
    if (localStorage.getItem('user')) {
      const data = JSON.parse(localStorage.getItem('user'))
      setUser(data)
    }
    fetchList()
  }, [])
  return (
    <div>
      <div className='box'>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Dashboard
          </Link>
          <Typography sx={{ color: 'text.primary' }}>Wallet Addresses</Typography>
        </Breadcrumbs>
      </div>
      <div className='text-inner'>

        <Card style={{ padding: '10px', paddingBottom: '60px' }}>
          <h4>Primary Addresses</h4>
          {addressList.length > 0 &&
            <>
              {addressList.map((list, i) => (
                <div className='row'>
                  <div className='col-sm-10'>
                    <div className='wl-address'>
                      <p>{list.address} </p>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <h5>Balance</h5>
                    <div>
                      $0.00 USD
                    </div>
                    <div>
                      <FaEthereum />
                      0
                    </div>
                  </div>
                </div>
              ))
              }
            </>
          }

          <div className='row'>
            <div className='col-sm-12'>
              <h5>Add New Address</h5>
              <InputGroup>
                <Input
                  value={newAddress}
                  onChange={(e) => { setNewAddress(e.target.value) }} />
                <Button color="dark" onClick={addAddress}
                >
                  Add Address
                </Button>
              </InputGroup>
            </div>
          </div>


        </Card>
      </div>
    </div>
  )
}

export default WalletAddress