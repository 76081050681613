import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import { PiUserCircleGearLight } from "react-icons/pi";
import axios from 'axios';
import Swal from 'sweetalert2';

function Settings() {
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [Confrmpass, setConfirmPass] = useState('');

    const updatePass = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/user/update`, { password: Confrmpass, email: user?.userid })
        setTimeout(() => {
            Swal.fire({
                icon: "success",
                title: "Successfully updated",
                showConfirmButton: false,
                timer: 2000
            });
            setUser('')
            setPass('')
        }, 1000);
    }

    useEffect(() => {
        const userData = localStorage.getItem('user');
        console.log(JSON.parse(userData))
        if (userData === null) {
            //                navigate('/login')
        } else {
            setUser(JSON.parse(userData));
        }
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Settings </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                    <h4>Profile Settings</h4>
                    <div>
                        {/* {JSON.stringify(user)} */}

                    </div>
                    <div className='text-center'>
                        <PiUserCircleGearLight size='85' />
                    </div>
                    <Form>
                        <FormGroup row>
                            <Label sm={2}> Name </Label>
                            <Col sm={10}>
                                <Input
                                    placeholder="with a placeholder"
                                    type="text" value={user?.name}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Email</Label>
                            <Col sm={10}>
                                <Input
                                    placeholder="with a placeholder"
                                    type="email" value={user?.userid} disabled
                                />
                            </Col>
                        </FormGroup>
                        <hr />
                        <FormGroup row>
                            <Label sm={2}>Old password</Label>
                            <Col sm={10}>
                                <Input
                                    placeholder="********"
                                    type="password" value={pass}
                                    onChange={(e) => { setPass(e.target.value) }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>New Password</Label>
                            <Col sm={10}>
                                <Input
                                    placeholder="*********"
                                    type="password" value={Confrmpass}
                                    onChange={(e) => { setConfirmPass(e.target.value) }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup switch>
                            <Input type="switch" role="switch" />
                            <Label check>Enable Two-Factor Authentication (2FA)</Label>
                        </FormGroup>
                        <FormGroup switch>
                            <Input type="switch" role="switch" />
                            <Label check>Notification Preferences (Email/SMS)</Label>
                        </FormGroup>
                        <br />
                        <Button type='button' color='warning' onClick={updatePass} block>Update</Button>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default Settings