// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


// import 'ckeditor5/ckeditor5.css';
// import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
import { Breadcrumbs, Typography,Backdrop, CircularProgress } from '@mui/material'
import { Button, Card, Col, Label, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useState } from 'react';


function AddNews() {

    const [addNEws, SetAddNews] = useState({ title: '', n_date: '', news_body: '' });
    const [open, setOpen] = useState(false)
    const [editorData, seteditorData] = useState('');

    const handleEditorChange=(event, editor)=>{
        const data = editor.getData();
        SetAddNews((prev)=>({...prev, news_body: data}))
        //console.log('Editor data:', data);
    }

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card className='px-5 py-5' >
                    <div>
                        <label>News title <span className='text-danger'>*</span></label>
                        <input type='text' className='form-control' value={addNEws.title}
                        onChange={(e)=>{ SetAddNews((prev)=>({...prev, title: e.target.value})) }}
                        />
                    </div>
                    <div>
                        <label>News date <span className='text-danger'>*</span></label>
                        <input type='date' className='form-control'
                        onChange={(e)=>{ SetAddNews((prev)=>({...prev, n_date: e.target.value })) }}
                        />
                    </div>
                    <Label>News body</Label>
                    {/* <CKEditor
                        editor={ClassicEditor}
                        data={addNEws.news_body}
                        onChange={handleEditorChange}
                        config={{
                            toolbar: [
                                'heading', '|', 'bold', 'italic', 'link', 'blockQuote', 'code', '|',
                                'bulletedList', 'numberedList', 'todoList', '|',
                                'outdent', 'indent', '|', 'alignment', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
                                'imageUpload', 'mediaEmbed', 'insertTable', 'tableProperties', 'tableCellProperties', '|',
                                'undo', 'redo'
                            ],
                            // Optional: Customize other configuration options
                            image: {
                                toolbar: [
                                    'imageTextAlternative', 'imageStyle:full', 'imageStyle:side', '|',
                                    'imageResize:25', 'imageResize:50', 'imageResize:75', 'imageResize:100'
                                ]
                            }
                        }}
                    /> */}
                    <div className='pt-2'>
                        <Button color='primary' block >Save News</Button>
                    </div>
                </Card>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>

    );
}

export default AddNews