import React, { useState, useEffect } from 'react'
import { Form, Row, Col, FormGroup, Label, Input, InputGroup, InputGroupText, Button } from 'reactstrap'
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin, FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { BsSendFill } from "react-icons/bs";
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2'
import { FaTelegram } from "react-icons/fa";


function ComunityForm() {
    const [questionList, setquestionList] = useState([]);
    const [addCommunity, setAddComunitty] = useState({
        qus: '', respond: '', ans: '', fname: '',lname: '', email: '', phone: '', twtr: '',
        linked: '', fb: '', instra: ''
    });
    const [open, setOpen] = useState(false);

    const sendComunity = async () => {
        if (addCommunity.qus === '') { return alert('Please select Question') }
        if (addCommunity.fname === '') { return alert('Please enter full name') }
        if (addCommunity.flname === '') { return alert('Please enter full name') }
        if (addCommunity.email === '') { return alert('Please enter email') }
        if (addCommunity.phone === '') { return alert('Please enter phone') }

        setOpen(true)
        const resAdd = await axios.post(`${process.env.REACT_APP_API_URL}/community/add`, addCommunity)
        setOpen(false)
        Swal.fire({
            icon: "success",
            title: "Successfully saved",
            showConfirmButton: false,
            timer: 2000
        });
        setAddComunitty({
            qus: '', respond: '', ans: '', fullname: '', email: '', phone: '', twtr: '',
            linked: '', fb: '',tel:'', instra: ''
        })
    }
    const fetchQus = async () => {
        const resaa = await axios.get(`${process.env.REACT_APP_API_URL}/question/list`);
        setquestionList(resaa.data.result)
    }
    useEffect(() => {
        fetchQus();
    }, [])
    return (
        <div>
            <div>
                <div className='py-5 px-5'>
                    <h2 className='text-center pb-2'>Welcome to the Nugget Trap Community!</h2>
                    <p>
                        At Nugget Trap, we're building a vibrant community focused on driving digital transformation
                        in the mining industry. Through our utility token, Nugget Trap Gold Token (NGTG$$),
                        we simplify processes and enhance efficiency while rewarding loyalty with exclusive perks.
                        Whether you're a mining professional, blockchain enthusiast, or simply curious about the
                        future of digital assets, our community offers a space to learn, engage, and grow.
                        Join us as we combine tradition with innovation and shape the future of mining together!
                    </p>
                </div>
                <Form>
                    <Row className='bg-white'>

                        {/* <Col md={6} > */}
                        {/* <FormGroup tag="fieldset">
                                <Label>
                                    Response
                                </Label>
                                <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio" value='yes'
                                        onChange={(e) => { setAddComunitty((prev) => ({ ...prev, respond: 'yes' })) }}
                                    />
                                    <Label check>
                                        Yes
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            <FormGroup tag="fieldset">
                                <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio" value='no'
                                        onChange={(e) => { setAddComunitty((prev) => ({ ...prev, respond: 'no' })) }}
                                    />
                                    {' '}
                                    <Label check>
                                        No
                                    </Label>
                                </FormGroup>
                            </FormGroup> */}
                        {/* </Col> */}

                        <Col md={6} >
                            <FormGroup>
                                <Label>First Name</Label>
                                <Input
                                    type="text" value={addCommunity.fname}
                                    onChange={(e) => { setAddComunitty((prev) => ({ ...prev, fname: e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} >
                            <FormGroup>
                                <Label>Last Name</Label>
                                <Input
                                    name="text" value={addCommunity.lname}
                                    onChange={(e) => { setAddComunitty((prev) => ({ ...prev, lname: e.target.value })) }}
                                    />
                            </FormGroup>
                        </Col>
                        <Col md={6} >
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    type="text" value={addCommunity.email}
                                    onChange={(e) => { setAddComunitty((prev) => ({ ...prev, email: e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} >
                            <FormGroup>
                                <Label>Phone</Label>
                                <Input
                                    type="text" value={addCommunity.phone}
                                    onChange={(e) => { setAddComunitty((prev) => ({ ...prev, phone: e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className='input-grp'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaSquareXTwitter />
                                    </InputGroupText>
                                    <Input type='text' value={addCommunity.twtr}
                                        onChange={(e) => { setAddComunitty((prev) => ({ ...prev, twtr: e.target.value })) }} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6} className='input-grp'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaLinkedin />
                                    </InputGroupText>
                                    <Input type='text' value={addCommunity.linked}
                                        onChange={(e) => { setAddComunitty((prev) => ({ ...prev, linked: e.target.value })) }} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6} className='input-grp'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaFacebookSquare />
                                    </InputGroupText>
                                    <Input type='text' value={addCommunity.fb}
                                        onChange={(e) => { setAddComunitty((prev) => ({ ...prev, fb: e.target.value })) }} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6} className='input-grp'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaInstagramSquare />
                                    </InputGroupText>
                                    <Input type='text' value={addCommunity.instra}
                                        onChange={(e) => { setAddComunitty((prev) => ({ ...prev, instra: e.target.value })) }} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6} className='input-grp'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaTelegram />
                                    </InputGroupText>
                                    <Input type='text' value={addCommunity.tel}
                                        onChange={(e) => { setAddComunitty((prev) => ({ ...prev, tel: e.target.value })) }}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6} >
                        <FormGroup>
                                <Label >Upload Avatar</Label>
                                <input type='file' className='form-control' />
                            </FormGroup>
                        </Col>
                        <Col md={6} >
                        <FormGroup>
                        <Label>Question</Label>
                                <Input
                                    name="selectMulti"
                                    type="select" value={addCommunity.qus}
                                    onChange={(e) => { setAddComunitty((prev) => ({ ...prev, qus: e.target.value })) }}
                                >
                                    <option>Select Question </option>
                                    {questionList.length > 0 &&
                                        <>
                                            {questionList.map((list, i) => (
                                                <option key={i} value={list.question}>
                                                    {list.question}
                                                </option>
                                            ))}
                                        </>
                                    }


                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={6} >
                            <FormGroup>
                                <Label>Answer</Label>
                                <Input
                                    name="text"
                                    type="text" value={addCommunity.ans}
                                    onChange={(e) => { setAddComunitty((prev) => ({ ...prev, ans: e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className='offset-sm-3 input-grp'>
                            <FormGroup>
                                <Button color='primary' className='fw-bold' block onClick={sendComunity}>Send
                                    <BsSendFill />
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Form>
            </div>
        </div>
    )
}

export default ComunityForm