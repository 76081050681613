import React from 'react'
import { Col, Row, Table } from 'reactstrap'
import digitaltoken from '../img/digital-token.jpeg';

function Tokenomics() {
    return (
        <div>
            <div className='pt-5 pb-5  bbg1 '>
                <div className='container'>
                    <Row>
                        <Col sm={6}>
                            <img src={digitaltoken} alt='' className='width-80 img-height' style={{ height: 'auto', maxHeight: '400px' }} />
                        </Col>
                        <Col sm={6}>
                            <h2 className='text-center wel-text pt-3' style={{ marginBottom: '20px' }}>Tokenomics Model</h2>
                            <p className='pt-2 text1 text-justify' style={{ marginTop: '20px' }}>
                                Developing a robust tokenomics model is essential for the successful launch of a token
                                associated with Cunningham Mining. Tokenomics refers to the economic model governing the
                                issuance, distribution, and utilization of tokens within a specific ecosystem.
                                Given Cunningham Mining's context in the mineral resources and energy sector,
                                here's a tokenomics plan for the project.
                            </p>
                            
                        </Col>
                        <Col sm={12}>
                        <p className='pt-5 text1 text-justify' >
                            Real World Assets (RWAs) represent a revolutionary shift in the digital economy by bridging the gap
                             between traditional finance and blockchain technology. These tangible assets, which includes real
                              estate, commodities, and even art, can be tokenized to provide liquidity, transparency,
                               and accessibility to a broader audience. By leveraging smart contracts, RWAs enable fractional
                                ownership, reducing barriers to entry for investors and democratizing wealth creation. 
                                As regulatory frameworks evolve and adoption increases, RWAs like Nugget Trap Gold stand poised
                                 to transform investment landscapes, streamline asset management, and unlock new avenues for 
                                 capital flow, making them an essential component of the future financial ecosystem.
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='sec-4'>
                    <div className='container'>
                        <div className=''>
                            <Table className='info-tbl rrr'>
                                <tbody>
                                    <tr>
                                        <th scope="row"> Token Name </th>
                                        <td> NUGGET TRAP (NGTG$$)</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"> Token Standard </th>
                                        <td> ERC-20 (or suitable standard for your blockchain platform)</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Circulating Supply </th>
                                        <td> 1 Billion</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Treasury </th>
                                        <td> 4 Billion</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Max Supply </th>
                                        <td> 5 Billion</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Issuance for the offering </th>
                                        <td>100,000,000 NGT Token (One Hundred Million NGTG$$ )</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Price of each Digital Token Unit </th>
                                        <td>$0.60 USD</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Registration Platform for users </th>
                                        <td>OriginatorX Issuing Platform</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Wallets</th>
                                        <td>Metamask Wallet, Smartpay Wallet</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Acceptable forms of Payments</th>
                                        <td>Wire, ETH, BTC,USDT</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='container'>
                    <p>
                        Tokenomics 2: <br /><br />
                        The company may trigger a listing on multiple crypto exchanges on accomplishing the target of
                        $60Million raise.
                    </p>
                    <h4 className='h2-color'>Max Supply Distribution Strategy:</h4>
                    <ul>
                        <li>
                            Platform Reserve (50%): Reserved to support future platform initiatives, operational liquidity, and expansion.
                        </li>
                        <li>Compensation and User Engagement (5%):
                            <ul>
                                <li>Staff & Employees</li>
                                <li>Marketing</li>
                                <li>
                                    User Rewards: Promotes active engagement by rewarding trading or holding activities, helping broaden NGTG$$ adoption
                                </li>
                                <li>
                                    Incentives on Exchanges
                                </li>
                            </ul>
                        </li>
                        <li>
                            Ecosystem and Partnerships (5%): Allocated for integration with partnered exchanges and platforms.
                        </li>
                        <li>
                            Advisors and Ecosystem Development Fund (20%): Reserved for early contributors, with a vesting structure to ensure alignment with long-term goals.
                        </li>
                        <li>
                            Public Sale (20%): Available on exchanges to increase liquidity and distribution, expanding NGTG$$'s reach.
                        </li>
                    </ul>
                    <br /><br />
                    <h5 className='h2-color'>Strategic Development:</h5>
                    <ul>
                        <li>
                            Service Accessibility: Listing NGTG$$ on major exchanges ensures global accessibility and ease of use.
                        </li>
                        <li>
                            Controlled Supply: With capped supply and selective burning based on platform activity, scarcity is maintained.
                        </li>
                        <li>
                            Transparency in Utility: Regular updates on new functionalities, integrations, and service availability will be provided to support informed usage.
                        </li>
                    </ul>
                    <h5 className='h2-color'>Transparency and Governance:</h5>
                    <ul>
                        <li>
                            Supply Reporting: Periodic reports on circulating supply, reserves, and any burned tokens will be accessible to promote transparency. Regular audits will keep the community informed on token metrics.
                        </li>
                        <li>
                            Clear Service Roadmap: Planned service releases and partnerships will be publicly disclosed to engage the community on future directions and innovations.
                        </li>
                        <li>
                            Decentralized Governance Participation: Governance is maintained through an advisory role of accepted industry experts. Limited governance rights will be available to token holders for specific platform parameters, allowing community input while maintaining platform stability. At all times communications with Token holders is available and addressable through multiple supported communication channels.
                        </li>
                    </ul>
                    <h5 className='h2-color'>Security Measures:</h5>
                    <ul>
                        <li>
                            Token Security: NGTG$$ is developed on a secure blockchain (Ethereum) with robust security measures to safeguard transactions.
                        </li>
                        <li>
                            Compliance and Audits: Regular security audits by third-party firms ensure that token and platform protocols remain secure against vulnerabilities.
                        </li>
                        <li>
                            Data Privacy Compliance: Adhering to global standards, all user data within the Nugget Trap ecosystem is handled securely, ensuring data privacy and compliance.
                        </li>
                    </ul>

                    <h5 className='h2-color'>Token Utility:</h5>
                    <ul>
                        <li>
                            Ownership and Governance: NGTG$$ Token holders have voting rights in the project's decision-making processes, including budget allocation, mining strategies, and future developments.
                        </li>
                        <li>
                            Exclusive Access: Holders may have exclusive access to mining reports, industry insights, and events related to the Golden Triangle mining operations.
                        </li>
                    </ul>
                    <h5 className='h2-color'>Roadmap and Milestones:</h5>
                    <ul>
                        <h6>Phase 1: Exploration and Setup</h6>
                        <li>
                            Develop infrastructure, conduct geological surveys, and initial exploration of the Nugget Trap property.
                        </li>
                        <h6>Phase 2: Mining Commencement</h6>
                        <li>Begin active mining operations, invest in advanced equipment, and optimize production processes.</li>
                        <h6>Phase 3: Expansion and Sustainability</h6>
                        <li>
                            Expand mining operations to optimize resource extraction, enhance environmental sustainability, and increase profitability.
                        </li>
                        <h6>Phase 4: Ecosystem Development</h6>
                        <li>
                            Introduce partnerships, enhance community engagement, and explore collaborations with other projects in the industry.
                        </li>
                    </ul>
                    <h5 className='h2-color'>Legal and Compliance:</h5>
                    <ul>
                        <li>
                            Comply with relevant securities regulations and ensure transparent communication with regulatory authorities.
                        </li>
                        <li>
                            Implement robust KYC/AML procedures for all token holders to maintain compliance with global regulations.
                        </li>
                    </ul>

                    <h3 className='h2-color'>Approved Activities:</h3>
                    <ul>
                        <li>Work Related Structures: 6 structures. (0.03 ha) </li>
                        <li>Mining Areas: 3 areas, 25m W * 100m L (0.75 ha) </li>
                        <li>Processing infrastructure: Coarse Tailings stockpiles, Trommel and Highbanker (0.015 ha) </li>
                        <li>Test pits: 20 sites, 1.5m L * 1m W * 1.5m D each (0.01ha) </li>
                        <li>New Access Construction: 1500m * 3m W (0.45ha) </li>
                        <li>1. Existing Access Modification: 1000m L * 3m W (0.3ha) </li>
                        <li>Other: Storage of up to 1600L of fuel in barrels. </li>
                        <li>For a total disturbance area of 2.5 ha. </li>
                    </ul>

                    <h3 className='h2-color'>Activities not approved:</h3>
                    <ul>
                        <li>Fording of watercourses is not authorized.</li>
                        <li>Suction Dredging and/or Enhanced Sniping are not authorized.</li>
                        <li>Individual settling ponds must not be constructed greater than 2.5 m above grade or be capable of impounding greater than 30,000m³ volume.</li>
                    </ul>

                    <h3 className='h2-color'>Disclaimer:</h3>
                    <p>
                        The Nugget Trap Gold Tokenomics model is subject to change based on market conditions, regulatory requirements, and project needs. Token holders are encouraged to stay updated with project announcements for the latest information.

                    </p>
                    <p>
                    Please note that the above Tokenomics model is a general framework and should be tailored to meet the specific needs and goals of Cunningham Mining.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Tokenomics
