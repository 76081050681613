import React, { useEffect, useState } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { FaDotCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Swal from 'sweetalert2'
import { InputGroup, InputGroupText } from 'reactstrap'
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin, FaFacebookSquare } from "react-icons/fa";

import pollImg2 from '../img/poll-img1.jpg'
import * as Yup from 'yup';

const validationSchema = Yup.object({
    username: Yup.string()
        .required('Username is required')
        .min(3, 'Username must be at least 3 characters long'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters long')
});

function Poll() {
    const [qusList, setQusList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [maxSteps, setmaxSteps] = useState(0)
    const [newPools, setNewPools] = useState({ fname: '', lname: '', contact: '', email: '', twtr: '', linked: '', fb: '' })
    const [open, setOpen] = useState(false);


    const fetchList = async () => {
        const resist = await axios.get(`${process.env.REACT_APP_API_URL}/pool/list`)
        if (resist.data.error === 0) {
            for (let i = 0; i < resist.data.result.length; i++) {
                resist.data.result[i].selectedOpt = false;
            }
            setTimeout(() => {
                console.log(resist.data.result)
                setmaxSteps(resist.data.result.length)
                setQusList(resist.data.result);
            }, 100);
        }
        console.log(resist)
    }
    const toggleStatus = (opt, index) => {
        const newData = [...qusList];
        newData[index] = {
            ...newData[index],
            selectedOpt: opt
        };
        console.log(newData)
        setQusList(newData);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handalSubmit = async () => {
        //const [newPools, setNewPools] = useState({ fname: '', lname: '', contact: '',email:'', })
        //
        if (newPools.fname === '') { return alert('Please enter your name.') }
        if (newPools.lname === '') { return alert('Please enter your name.') }
        if (newPools.contact === '') { return alert('Please enter contact number.') }
        if (newPools.email === '') { return alert('Please enter email.') }
        var allData = newPools;
        allData.allQus = JSON.stringify(qusList);


        setOpen(true);
        const resAdd = await axios.post(`${process.env.REACT_APP_API_URL}/pool/submit`, allData)
        if (resAdd.data.error === 0) {
            // alert('Saved')
            Swal.fire({
                icon: "success",
                title: "Successfully saved",
                showConfirmButton: false,
                timer: 2000
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "An error occurred",
                text: "Something is error",
                showConfirmButton: true
            });
        }
        setOpen(false);
        setNewPools({ fname: '', lname: '', contact: '', email: '', twtr: '', linked: '', fb: '' })
        fetchList()
    }
    const handalChange = (data) => {
        console.log(data.target.value)
        setActiveStep(data.target.value)
    }
    useEffect(() => {
        console.log(qusList)
        fetchList()
    }, [])

    return (
        <div>
            <h4 className='text-center'>Have Your Say in Our Poll!</h4>
            <div>
                <img src={pollImg2} alt='' className='poll-img' />
            </div>
            <p className='pt-5 text1' style={{ color: 'black' }}>
                Your opinion is vital to shaping the future of NuggetTrap. This quick poll is your chance to share your thoughts and influence how we evolve.
                Take a moment to participate and help us make NuggetTrap even better for the mining community
            </p>
            <div className='pt-5'>
                <Form>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>First Name</Label>
                                <Input
                                    placeholder="Enter your first name"
                                    type="text" value={newPools.fname}
                                    onChange={(e) => { setNewPools((prev) => ({ ...prev, fname: e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>Last Name
                                </Label>
                                <Input
                                    placeholder="Enter your last name"
                                    type="text" value={newPools.lname}
                                    onChange={(e) => { setNewPools((prev) => ({ ...prev, lname: e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Contact</Label>
                                <Input
                                    placeholder="Enter your contact number"
                                    type="text" value={newPools.contact}
                                    onChange={(e) => { setNewPools((prev) => ({ ...prev, contact: e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    placeholder="Enter your email"
                                    type="email" value={newPools.email}
                                    onChange={(e) => { setNewPools((prev) => ({ ...prev, email: e.target.value })) }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className='input-grp'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaSquareXTwitter />
                                    </InputGroupText>
                                    <Input type='text' value={newPools.twtr}
                                        onChange={(e) => { setNewPools((prev) => ({ ...prev, twtr: e.target.value })) }} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6} className='input-grp'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaLinkedin />
                                    </InputGroupText>
                                    <Input type='text' value={newPools.linked}
                                        onChange={(e) => { setNewPools((prev) => ({ ...prev, linked: e.target.value })) }} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6} className='input-grp'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaFacebookSquare />
                                    </InputGroupText>
                                    <Input type='text' value={newPools.fb}
                                        onChange={(e) => { setNewPools((prev) => ({ ...prev, fb: e.target.value })) }} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Upload Avatar</Label>
                                <Input
                                    type="file"
                                />
                            </FormGroup>
                        </Col>


                    </Row>
                    <Row>

                        <Col md={6} >
                            <FormGroup>
                                <Label>Select Poll</Label>
                                <select className='form-control' onChange={(e) => { handalChange(e) }}>
                                    <option value=''>Select option</option>
                                    {qusList.map((list, i) => (
                                        <option key={i} value={i} >{list.qus} </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label>Start date</Label>
                                <Input
                                    type="text"
                                    value={qusList[activeStep]?.start_date}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label>End date</Label>
                                <Input
                                    type="text" value={qusList[activeStep]?.end_date}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Box sx={{ width: '100%', p: 2 }}>

                {/* {JSON.stringify(qusList[activeStep]?.qus)} */}
                <div className='pt-5' >
                    <h4> {qusList[activeStep]?.qus}</h4>
                    <div className='list-data'>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            aria-label="contacts"
                        >
                            <ListItem disablePadding className={`${qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option1 ? 'active' : ''}`}>
                                <ListItemButton
                                    onClick={() => toggleStatus(qusList[activeStep]?.option1, activeStep)}>
                                    <ListItemIcon>
                                        {qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option1 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                    </ListItemIcon>
                                    <ListItemText primary={qusList[activeStep]?.option1} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding className={`${qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option2 ? 'active' : ''}`}>
                                <ListItemButton onClick={() => toggleStatus(qusList[activeStep]?.option2, activeStep)} >
                                    <ListItemIcon>
                                        {qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option2 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                    </ListItemIcon>
                                    <ListItemText primary={qusList[activeStep]?.option2} />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding className={`${qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option3 ? 'active' : ''}`}>
                                <ListItemButton onClick={() => toggleStatus(qusList[activeStep]?.option3, activeStep)}>
                                    <ListItemIcon>
                                        {qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option3 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                    </ListItemIcon>
                                    <ListItemText primary={qusList[activeStep]?.option3} />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding className={`${qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option4 ? 'active' : ''}`}>
                                <ListItemButton onClick={() => toggleStatus(qusList[activeStep]?.option4, activeStep)}>
                                    <ListItemIcon>
                                        {qusList[activeStep]?.selectedOpt === qusList[activeStep]?.option4 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                    </ListItemIcon>
                                    <ListItemText primary={qusList[activeStep]?.option4} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </Box>

            <Row>
                <Col sm={6} pt={5} pb={5} className='offset-sm-3'>
                    <Button variant="contained" fullWidth onClick={handalSubmit}>Submit</Button>
                </Col>
            </Row>
            {/* <PollQustions /> */}


        </div>
    )
}

export default Poll