import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumbs, Card } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { AiFillRobot } from 'react-icons/ai';
import { MdSubscriptions } from "react-icons/md";
import { FcInvite } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { FaEthereum } from "react-icons/fa";
import { Table } from 'reactstrap';
import { FiEye } from "react-icons/fi";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { FaArrowAltCircleUp } from "react-icons/fa";



function Home() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    useEffect(() => {
        const userData = localStorage.getItem('user');
        console.log(JSON.parse(userData))
        if (userData === null) {
            navigate('/login')
        } else {
            setUser(JSON.parse(userData));
        }
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>

                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', height: '300px' }}>
                    {user?.level === 0 ? (
                        <div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Card className='pb-5'>
                                        <h3>Balance</h3>
                                        <div className='flex blance-text'>
                                            <div>
                                                <FaEthereum  />
                                            </div>
                                            <div>
                                                123.00 ETH
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className='col-sm-8'>
                                    <h3 className='text-center'>History</h3>

                                    <hr />
                                    <div>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>#</td>
                                                    <td>Status</td>
                                                    <td>Amount</td>
                                                    <td>Token Category</td>
                                                    <td>Release Date</td>
                                                    <td>Action</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <FaArrowAltCircleDown className='text-success' />
                                                    </td>
                                                    <td>Received</td>
                                                    <td>10.5 ECT</td>
                                                    <td>Promotor </td>
                                                    <td>{new Date().getDate()} / {new Date().getMonth() + 1}  /{new Date().getFullYear()}</td>
                                                    <td>
                                                        <FiEye />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <FaArrowAltCircleUp className='text-danger' />
                                                    </td>
                                                    <td>Send</td>
                                                    <td>10.5 ECT</td>
                                                    <td>Promotor </td>
                                                    <td>{ new Date().getDate()} / {new Date().getMonth() + 1}  /{new Date().getFullYear()}</td>
                                                    <td>
                                                        <FiEye />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ) : (
                        <div className='dashboard-ul'>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AiFillRobot />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Role" secondary="Total role" />
                                <ListItemText id="switch-list-label-wifi" primary="6" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <MdSubscriptions />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary=" Subscription Master" secondary="Total Subscription" />
                                <ListItemText id="switch-list-label-wifi" primary="3" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FcInvite />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Invite" secondary="Total Invite" />
                                <ListItemText id="switch-list-label-wifi" primary="4" />
                            </ListItem>
                        </div>
                    )}
                </Card>
            </div>
        </div>
    )
}

export default Home