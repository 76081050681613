import React from 'react'

import biconomy from '../img/marketplace/biconomy.png';
import xt from '../img/marketplace/xt-com.png';
import dex from '../img/marketplace/dex.png';
import uzx from '../img/marketplace/uzx.png';
import { Link } from 'react-router-dom';

import asc from '../img/marketplace/asc.png';
import toobit1 from '../img/marketplace/toobit.png';
import wex from '../img/marketplace/wex.png';

import imga11 from '../img/img11.png';
import img12 from '../img/img12.png';

import koinpark from '../img/marketplace/koinpark.png';

import bitmart from '../img/marketplace/bit-mart.png'
import Lbank from '../img/marketplace/lbank.png'
import brid from '../img/marketplace/brid.png'
import toobit from '../img/toobit.png';
import ascendex from '../img/ascendex.png'
import { Col, Row } from 'reactstrap';


function Cex() {
    return (
        <div>
            <div className='container'>
                <div className='client-galary pt-5'>
                    <h4 className='text-center text-a'>Now Listed On Major Crypto Exchanges (Cex)!</h4>
                    <div className='galary-inner pt-5'>
                        <div className='img-box'>
                            <Link to='https://www.biconomy.com/exchange/NGTG$$_USDT' target='_blank'>
                                <img src={biconomy} alt="" />
                            </Link>
                        </div>

                        <div className='img-box'>
                            <Link to='https://www.xt.com/en/trade/ngtg_usdt' target='_blank'>
                                <img src={xt} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://dex-trade.com/spot/trading/NGTG$$USDT?interface=classic' target='_blank'>
                                <img src={dex} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://uzx.com/#/exchange/ngtg_usdt' target='_blank'>
                                <img src={uzx} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://ascendex.com/en/cashtrade-spottrading/usdt/ngtg' target='_blank'>
                                <img src={asc} alt="" />
                            </Link>
                        </div>
                    </div>

                    <div className='galary-inner pt-5 pb-5'>
                        <div className='img-box'>
                            <Link to='https://www.toobit.com/en-US/spot/NGTG_USDT' target='_blank'>
                                <img src={toobit1} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://www.weex.com/trade/NGTG-USDT' target='_blank'>
                                <img src={wex} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://www.koinpark.com/trade/BTC-INR' target='_blank'>
                                <img src={koinpark} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://www.bitmart.com/trade/en-US?symbol=NGTG%24%24_USDT' target='_blank'>
                                <img src={bitmart} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://www.lbank.com/trade/ngtg_usdt' target='_blank'>
                                <img src={Lbank} alt="" />
                            </Link>
                        </div>
                        <div className='img-box'>
                            <Link to='https://bridgers.ai/' target='_blank'>
                                <img src={brid} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <Row>
                    <Col sm={6}>
                        <Link to="https://www.toobit.com/en-US/spot/NGTG_USDT" target='_blank'>
                            <h3 className='text-center cca pb-1'>
                                <img src={img12} alt='' />
                            </h3>
                        </Link>
                        <Link to="https://www.toobit.com/en-US/spot/NGTG_USDT" target='_blank'>
                            <img src={toobit} alt='' className='width-100 hight-1' />
                        </Link>

                    </Col>
                    <Col sm={6}>
                        <Link to="https://ascendex.com/en/cashtrade-spottrading/usdt/ngtg" target='_blank'>
                            <h3 className='text-center cca pb-1'>
                                <img src={imga11} alt='' />
                            </h3>
                        </Link>
                        <Link to="https://ascendex.com/en/cashtrade-spottrading/usdt/ngtg" target='_blank'>
                            <img src={ascendex} alt='' className='width-100 hight-1' />
                        </Link>

                    </Col>
                </Row>
            </div> */}


        </div>
    )
}

export default Cex