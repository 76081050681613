// Import necessary packages
import React, { useEffect, useState } from 'react';
import Web3 from 'web3';

const TokenInfo = (props) => {
    const [, setTotalSupply] = useState(null);
    const [balance, setBalance] = useState(null);
    const [, setError] = useState(null);

    // Token contract address and ABI
    const contractAddress = '0x810296898a4F870619B015C0B13C8c6E65b305e3';
    const targetAddress = '0x092D990971f40E012aa4d7C27D079E913bF250b8';
    const contractABI = [
        {
            "constant": true,
            "inputs": [],
            "name": "totalSupply",
            "outputs": [
                {
                    "name": "",
                    "type": "uint256"
                }
            ],
            "type": "function"
        },
        {
            "constant": true,
            "inputs": [
                {
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "balanceOf",
            "outputs": [
                {
                    "name": "",
                    "type": "uint256"
                }
            ],
            "type": "function"
        }
    ];
    const fetchData = async () => {
        try {
            // Check if MetaMask is installed
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                await window.ethereum.request({ method: 'eth_requestAccounts' });

                // Connect to the contract
                const contract = new web3.eth.Contract(contractABI, contractAddress);

                // Fetch total supply
                const supply = await contract.methods.totalSupply().call();
                setTotalSupply(supply); // Display raw value
                console.log(supply); // Display raw value

                // Fetch balance of target address
                const balanceOfTarget = await contract.methods.balanceOf(targetAddress).call();
                console.log(balanceOfTarget); // Display raw value
                setBalance(balanceOfTarget); // Display raw value
                localStorage.setItem('blanace', balanceOfTarget)

            } else {
                setError('MetaMask is not installed. Please install it to use this app.');
            }
        } catch (err) {
            setError('Failed to fetch data from the blockchain.');
            console.error(err);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(()=>{
        console.log(balance)
        props.blalace(balance)
       // setshowBal(balance)
    },[balance])

    return (
        <> 
            {/* <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Token Information</h1>
            {error ? (
                <p style={{ color: 'red' }}>{error}</p>
            ) : (
                <>
                    <p>blalnce  {balance ? balance : 'Loading...'}</p>
                    <p>showBal  { showBal} </p>
                </>
            )}
        </div> */}
        </>
    );
};

export default TokenInfo;
