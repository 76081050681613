import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@mui/material'
import { Button, Card, Col, Row, Form, FormGroup, Label, Input, Table } from 'reactstrap'
import axios from 'axios';
import { FaTrash } from 'react-icons/fa6';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';

function CommunityQuestions() {
    const [add_question, setadd_question] = useState('');
    const [questionList, setquestionList] = useState([]);
    const [open, setOpen] = useState(false)

    const saveMessage = async () => {
        if (add_question === '') { return alert('Please write question!') }
        setOpen(true)
        const resQus = await axios.post(`${process.env.REACT_APP_API_URL}/question/add`, { question: add_question });
        setOpen(false)
        Swal.fire({
            icon: "success",
            title: "Successfully saved",
            showConfirmButton: false,
            timer: 2000
        });
        setadd_question('')
        console.log(resQus)
        fetchQus();
    }
    const fetchQus = async () => {
        setOpen(true)
        const resaa = await axios.get(`${process.env.REACT_APP_API_URL}/question/list`);
        setOpen(false)
        setquestionList(resaa.data.result)
        console.log(resaa.data.result)
        
    }
    const deleteList = async (data) => {
        setOpen(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/question/delete`, { id: data.id });
        setOpen(false)
        fetchQus();
    }
    useEffect(() => {
        fetchQus();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Add Qus</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card className='pt-5 pb-5 ps-5'>
                    <Form>
                        <Row className='bg-white width-100'>
                            <Col md={6} className=''>
                                <FormGroup>
                                    <Label>Question</Label>
                                    <Input
                                        name="text"
                                        type="textarea" value={add_question}
                                        onChange={(e) => { setadd_question(e.target.value) }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Button color='primary' block className='fw-bold'
                                        onClick={saveMessage}
                                    >Add  {" "}

                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <h4>List </h4>
                                <Table className=''>
                                    <thead>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Question</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {questionList.length > 0 &&
                                            <>
                                                {questionList.map((list, i) => (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{list.question}</td>
                                                        <td>
                                                            <Button size='sm' color='danger' onClick={() => { deleteList(list) }} ><FaTrash /> </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        }
                                    </tbody>

                                </Table>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    )
}

export default CommunityQuestions