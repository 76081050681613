import React, { useState, useEffect } from 'react'

import { Card, Col, Row, Table, Button } from 'reactstrap'
import { Breadcrumbs, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { FaDotCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

import { useLocation } from 'react-router-dom';


function PollsDetails() {
    const location = useLocation();
    const [qusData] = useState(JSON.parse(location.state.all_querylist));

    useEffect(() => {
        console.log(location.state)
        console.log(qusData)
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Details</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card className='pt-5 pb-5 ps-5'>
                    <Row>
                        <Col sm={4}>
                            <p>Fullname: <b> {location.state.fname} {" "} {location.state.lname}</b></p>
                        </Col>
                        <Col sm={4}>
                            <p>Email: <b> {location.state.email} </b></p>
                        </Col>
                        <Col sm={4}>
                            <p>Contact: <b> {location.state.contact} </b></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                        {qusData &&
                        <>
                        {qusData.map((qusList,i)=>(
                            <>
                            {qusList.selectedOpt &&
                            <div className='pt-5' >
                            <h4>{i+1}. {qusList.qus}</h4>
                            <div className='list-data'>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    aria-label="contacts"
                                >
                                    <ListItem disablePadding className={`${qusList.selectedOpt === qusList.option1 ? 'active' : ''}`}>
                                        <ListItemButton >
                                            <ListItemIcon>
                                                {qusList.selectedOpt === qusList.option1 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                            </ListItemIcon>
                                            <ListItemText primary={qusList.option1} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding className={`${qusList.selectedOpt === qusList.option2 ? 'active' : ''}`}>
                                        <ListItemButton >
                                            <ListItemIcon>
                                                {qusList.selectedOpt === qusList.option2 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                            </ListItemIcon>
                                            <ListItemText primary={qusList.option2} />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding className={`${qusList.selectedOpt === qusList.option3 ? 'active' : ''}`}>
                                        <ListItemButton >
                                            <ListItemIcon>
                                                {qusList.selectedOpt === qusList.option3 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                            </ListItemIcon>
                                            <ListItemText primary={qusList.option3} />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem disablePadding className={`${qusList.selectedOpt === qusList.option4 ? 'active' : ''}`}>
                                        <ListItemButton >
                                            <ListItemIcon>
                                                {qusList.selectedOpt === qusList.option4 ? (<FaCheckCircle />) : (<FaDotCircle />)}
                                            </ListItemIcon>
                                            <ListItemText primary={qusList.option4} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                            }
                            </>
                        ))}
                        </>
                        }
                            
                        </Col>
                    </Row>
                </Card>

            </div>
        </div>
    )
}

export default PollsDetails