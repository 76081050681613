import React, { useState } from 'react'
import logo from '../img/logo.png'
import { Link, Route, Routes } from 'react-router-dom';
import { InputGroup, Input, Button } from 'reactstrap'

import fb from '../img/social/fb.png';
import twiter from '../img/social/twr.png';
import instra from '../img/social/ins.png'
import linkdin from '../img/social/link.png';
import tl from '../img/social/t.png';
import youtube from '../img/social/yout.png';
import tel from '../img/social/tel.png'

import { MdEmail } from "react-icons/md";

import { FacebookEmbed } from 'react-social-media-embed';
import Main from './Main';
import Location from './Location';
import Tokenomics from './Tokenomics';
import Cex from './Cex';
import Community from './Community';
import Ambassador from './Ambassador';
import Investor from './Investor';

import { FaBars } from "react-icons/fa";
import ViewMore from './ViewMore';
import NewEvent from './NewEvent';
import EventPage from './EventPage';
import axios from 'axios';
import Swal from 'sweetalert2'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as Yup from 'yup';
const FACEBOOK_DEFAULT_HEIGHT = 300;


function Home() {
    const [clicka, setClicka] = useState(false)
    const [activeItem, setActiveItem] = useState(null);
    const [subsEmail, setSubsEmail] = useState('')
    const [open, setOpen] = useState(false);
    const [embedHeight, setEmbedHeight] = useState(FACEBOOK_DEFAULT_HEIGHT);


    const emailSchema = Yup.string()
        .email('Invalid email format')
        .required('Email is required');

    const handlAddClass = () => {
        setClicka(!clicka)
    }
    const handleClick = (index) => {
        setActiveItem(index); // Update the active item on click
    };
    const handalSubs = async () => {
        //if (subsEmail === '') { alert('Please enter email!') };
        emailSchema
            .validate(subsEmail)
            .then(async () => {
                setOpen(true)
                const resSubs = await axios.post(`${process.env.REACT_APP_API_URL}/subsemail/add`, { email: subsEmail })
                setOpen(false)
                setSubsEmail('')
                Swal.fire({
                    icon: "success",
                    title: "Successfully send!",
                    showConfirmButton: false,
                    timer: 2000
                });
            })
            .catch((error) => {
                return alert(error.message)
                // setValidationError(error.message);
            });

    }
    const handleReady = (event) => {
        const iframe = event.target.getIframe();
        iframe.addEventListener('load', () => {
          setEmbedHeight((prevHeight) => prevHeight + 1);
        });
      };
    return (
        <div>
            <div>
                <div className='s-menu'>
                    <ul className='social-logo'>
                        <li><Link to="https://www.facebook.com/profile.php?id=61552312260533" target='_blank'> <img src={fb} alt='' /> </Link> </li>
                        <li><Link to="https://x.com/Nuggettrapgold" target='_blank'> <img src={twiter} alt='' /> </Link> </li>
                        <li><Link to="https://www.instagram.com/nuggettrapgold/" target='_blank'> <img src={instra} alt='' /> </Link> </li>
                        <li><Link to="https://www.linkedin.com/company/nugget-trap" target='_blank'> <img src={linkdin} alt='' /> </Link> </li>
                        <li><Link to="https://www.threads.net/@nuggettrapgold" target='_blank'> <img src={tl} alt='' /> </Link> </li>
                        <li><Link to="https://www.youtube.com/@GoingforGoldNGT" target='_blank'> <img src={youtube} alt='' /> </Link> </li>
                        <li><Link to="https://t.me/nuggettrapgold" target='_blank'> <img src={tel} alt='' /> </Link> </li>
                        {/* https://t.me/nuggettrapgold */}
                    </ul>
                </div>
                <div className='manu-nav pt-1 pb-1'>
                    <div>
                        <Link to='/'>
                            <img src={logo} className='logo-nav' style={{ height: '60px' }} alt='' />
                        </Link>
                    </div>
                    <div className={"secnd " + (clicka ? 'show' : 'hidden')} >
                        <ul>
                            <li><Link to='https://nuggettrap.com/uploads/Nugget Trap Tokenomics White Paper 7Nov24.pdf' target='_blank'>Whitepaper</Link> </li>
                            {/* <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                <Link className={activeItem === 0 ? 'active' : ''}
                                    onClick={() => handleClick(0)} to=''></Link>
                            </li> */}
                            {/* <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                <Link className={activeItem === 1 ? 'active' : ''}
                                    onClick={() => handleClick(1)} to='/home/prospectors'>Prospectors</Link>
                            </li> */}
                            <li className='parent-menu' >
                                <Link >Prospectors</Link>
                                <ul className='sub-menu'>
                                    <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                        <Link className={activeItem === 1 ? 'active' : ''}
                                            onClick={() => handleClick(1)} to='/home/prospectors'>Prospectors</Link> </li>
                                    <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                        <Link className={activeItem === 0 ? 'active' : ''}
                                            onClick={() => handleClick(0)} to='/home/assay-report'>Assay Report</Link> </li>
                                </ul>
                            </li>
                            <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                <Link className={activeItem === 2 ? 'active' : ''}
                                    onClick={() => handleClick(2)} to='/home/tokenomics'>Digital Assets Tokenomics</Link>
                            </li>
                            <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                <Link className={activeItem === 3 ? 'active' : ''}
                                    onClick={() => handleClick(3)} to='/home/cex'>CEX</Link>
                            </li>
                            <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                <Link className={activeItem === 4 ? 'active' : ''}
                                    onClick={() => handleClick(4)} to='/home/community'>Community</Link>
                            </li>
                            <li className='parent-menu' >
                                <Link >News & Events</Link>
                                <ul className='sub-menu'>
                                    <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                        <Link className={activeItem === 6 ? 'active' : ''}
                                            onClick={() => handleClick(6)} to='/home/news'>News</Link> </li>
                                    <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                        <Link className={activeItem === 7 ? 'active' : ''}
                                            onClick={() => handleClick(7)} to='/home/event'>Events</Link> </li>
                                </ul>
                            </li>
                            <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}>
                                <Link className={activeItem === 5 ? 'active' : ''}
                                    onClick={() => handleClick(5)} to='/home/ambassador'>Ambassador Program</Link>
                            </li>
                            <li onClick={() => { if (clicka === true) { setClicka(!clicka) } }}><Link to='/login' className='log-btn'>Login</Link> </li>
                        </ul>
                    </div>
                    <div className='pt-1 icon'>
                        <FaBars onClick={handlAddClass} />
                    </div>
                </div>
            </div>

            <Routes>
                <Route path='/' element={<Main />} ></Route>
                <Route path='/prospectors' element={<Investor />} ></Route>
                <Route path='/assay-report' element={<Location />} ></Route>
                <Route path='/tokenomics' element={<Tokenomics />} ></Route>
                <Route path='/cex' element={<Cex />} ></Route>
                <Route path='/community' element={<Community />} ></Route>
                <Route path='/ambassador' element={<Ambassador />} ></Route>
                <Route path='/view-more' element={<ViewMore />} ></Route>
                <Route path='/news' element={<NewEvent />} ></Route>
                <Route path='/event' element={<EventPage />} ></Route>

                {/* <Route path='/login' element={<Ambassador />} ></Route> */}
            </Routes>

            <div>
                <div className='footer'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='pt-5'>
                                    <img src={logo} className='footer-logo' alt='' />
                                    <p className=' desc-text'>We would like to welcome you to the Nugget Trap gold placer mine project</p>
                                    <div className='subs-form'>
                                        <InputGroup>
                                            <Input className='subs' value={subsEmail}
                                                onChange={(e) => { setSubsEmail(e.target.value) }} />
                                            <Button className='subs-btn' onClick={handalSubs}>
                                                <MdEmail /> Subscribe
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-3 pt-5 footer-menu'>
                                <h5 >Quick links</h5>
                                <ul>
                                    {/* Nugget Trap Tokenomics White Paper 7Nov24.pdf */}
                                    <li><Link to='/'>Home</Link> </li>
                                    <li><Link to='/home/assay-report'>Assay Report</Link> </li>
                                    <li><Link to='/home/prospectors'>Prospectors</Link> </li>
                                    <li><Link to='https://nuggettrap.com/uploads/NuggetTrapPrivacyPolicy.pdf' target='_blank'>Privacy Policy</Link> </li>
                                    <li><Link to='https://nuggettrap.com/uploads/NuggetTrapRiskPolicy.pdf' target='_blank'>Risk Policy</Link> </li>
                                    <li><Link to='https://nuggettrap.com/uploads/TermsandConditions.pdf'>Terms and Conditions</Link> </li>
                                </ul>
                            </div>
                            <div className='col-sm-3 pt-5 '>
                                <h5 >Follow Us</h5>
                                {/* <ul className='social-logo'>
                                    <li><Link> <img src={fb} alt='' /> </Link> </li>
                                    <li><Link> <img src={twiter} alt='' /> </Link> </li>
                                    <li><Link> <img src={instra} alt='' /> </Link> </li>
                                    <li><Link> <img src={linkdin} alt='' /> </Link> </li>
                                    <li><Link> <img src={tl} alt='' /> </Link> </li>
                                    <li><Link> <img src={youtube} alt='' /> </Link> </li>
                                    <li><Link> <img src={tel} alt='' /> </Link> </li>
                                </ul> */}
                                <div className='fb-page'>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <FacebookEmbed
                                        onReady={handleReady}
                                         url="https://www.facebook.com/61552312260533/videos/1222033735580216"
                                          />
                                          {/* width={300} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copy-right'>
                    <p className='copy-text'>© {new Date().getFullYear()} <Link to="https://nuggettrap.com/">Nuggettrap</Link> . All rights reserved.</p>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    )
}

export default Home