import React, { useState } from 'react';
import { Row, Col, Label, Input, Button, Form, InputGroup } from 'reactstrap';
import { FaTelegram, FaFacebook, FaLinkedin, FaSearch } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";


function Ambassador() {
  const [selectButton, setSeleButn] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [referId, setReferId] = useState(null);
  const [isDisable, setisDisable] = useState(true);

  const [applyProgram, setapplyProgram] = useState({
    fname: '', lname: '', telegram: '', facebook: '', twitter: '',
    threads: '', instagram: '', linkedIn: '', youtube: '', email: '', why_join: '', refer_id: referId
  });

  const clickButton = (btn) => {
    setSeleButn(btn);
  };
  const onChange = (value) => {
    console.log("Captcha value:", value);
}

  const searchMedia = async () => {
    if (!id || id === '') { return alert('Please enter ID'); }
    setOpen(true);
    const resultData = await axios.get(`${process.env.REACT_APP_API_URL}/ambassador/search?${selectButton}=${id}`);
    setOpen(false);
    if (resultData.data.result.length > 0) {
      setisDisable(false);
      setReferId(resultData.data.result[0].id);
      setapplyProgram((prev) => ({ ...prev, refer_id: resultData.data.result[0].id }));
      Swal.fire({
        icon: "success",
        title: "Verified",
        showConfirmButton: false,
        timer: 2000
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text: "Id Not found",
        showConfirmButton: true
      });
    }
    console.log(resultData);
  };

  const handalSave = async () => {
    if (applyProgram.fname === '') return alert('Please enter first name');
    if (applyProgram.lname === '') return alert('Please enter last name');
    if (applyProgram.email === '') return alert('Please enter email');
    if (applyProgram.telegram === '' || applyProgram.facebook === '' ||
      applyProgram.twitter === '' || applyProgram.threads === '' ||
      applyProgram.instagram === '' || applyProgram.linkedIn === '' ||
      applyProgram.youtube === '') {
      return alert('Please enter any ID !!');
    }
    setOpen(true);
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/ambassador/add`, applyProgram);
    setOpen(false);
    console.log(result);
    setapplyProgram({
      fname: '', lname: '', telegram: '', facebook: '', twitter: '',
      threads: '', instagram: '', linkedIn: '', youtube: '', email: '', why_join: '', refer_id: null
    });
    setReferId(null);
    setisDisable(true);
    setSeleButn(null);
    Swal.fire({
      icon: "success",
      title: "Successfully saved !",
      showConfirmButton: false,
      timer: 2000
    });
  };

  return (
    <div>
      {/* Title Section */}
      <div className="pt-5 pb-5 bbg1 upper-portion text-center" style={{ backgroundColor: 'black', color: 'white' }}>
        <h3 className="wel-text">Active Ambassador Check & Verification</h3>
      </div>

      {/* Social Media Buttons and Search Bar */}
      <div className="container pt-4 text-center">
        <Form className="button-div mb-3">
          <Button type="button" color="primary" onClick={() => { clickButton('telegram') }}
            className={selectButton === 'telegram' ? 'active' : ''}>
            Telegram
            <FaTelegram />
          </Button>
          <Button type="button" color="primary" onClick={() => { clickButton('facebook') }}
            className={selectButton === 'facebook' ? 'active' : ''}>Facebook
            <FaFacebook />
          </Button>
          <Button type="button" color="primary" onClick={() => { clickButton('linked_in') }}
            className={selectButton === 'linked_in' ? 'active' : ''}>LinkedIn
            <FaLinkedin />
          </Button>

          <Button type="button" color="primary" onClick={() => { clickButton('twitter') }}
            className={selectButton === 'twitter' ? 'active' : ''}>X (Twitter)
            <FaXTwitter />
          </Button>
        </Form>

        <InputGroup className="mb-4">
          <Input placeholder="Search by ID" value={id}
            onChange={(e) => { setId(e.target.value) }} />
          <Button color="light" onClick={searchMedia}>
            <FaSearch />
          </Button>
        </InputGroup>
      </div>

      {/* Apply For The Program Section */}
      <div className="bg-k">
        <div className="container pt-5 pb-5 bgform">
          <h3 className="text-center">Apply For The Program</h3>
          <Row>
            <Col sm={6}>
              <Label for="exampleEmail">First Name</Label>
              <Input type="text" value={applyProgram.fname}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, fname: e.target.value })) }} />
            </Col>
            <Col sm={6}>
              <Label for="exampleEmail">Last Name</Label>
              <Input type="text" value={applyProgram.lname}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, lname: e.target.value })) }} /></Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Label for="exampleEmail">Telegram ID</Label>
              <Input type="text" value={applyProgram.telegram}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, telegram: e.target.value })) }} />
            </Col>
            <Col sm={6}>
              <Label for="exampleEmail">Facebook Link</Label>
              <Input type="text" value={applyProgram.facebook}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, facebook: e.target.value })) }} />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Label for="exampleEmail">X (Twitter) ID </Label>
              <Input type="text" value={applyProgram.twitter}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, twitter: e.target.value })) }} />
            </Col>
            <Col sm={6}>
              <Label for="exampleEmail">Threads Link</Label>
              <Input type="text" value={applyProgram.threads}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, threads: e.target.value })) }} />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Label for="exampleEmail">Instagram ID</Label>
              <Input type="text" value={applyProgram.instagram}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, instagram: e.target.value })) }} />
            </Col>
            <Col sm={6}>
              <Label for="exampleEmail">LinkedIn Link</Label>
              <Input type="text" value={applyProgram.linkedIn}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, linkedIn: e.target.value })) }} />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Label for="exampleEmail">Youtube</Label>
              <Input type="text" value={applyProgram.youtube}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, youtube: e.target.value })) }} />
            </Col>
            <Col sm={6}>
              <Label for="exampleEmail">Email</Label>
              <Input type="text" value={applyProgram.email}
                onChange={(e) => { setapplyProgram((prev) => ({ ...prev, email: e.target.value })) }} />
            </Col>
            <Col sm={6}>
              <Label for="exampleEmail">Upload Avatar</Label>
              <Input type="file" />
            </Col>
            <Col sm={6}>
              <Label for="exampleEmail">Brief Description</Label>
              <Input type="text" 
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className='pt-3'>
              <ReCAPTCHA
                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                onChange={onChange}
                theme="dark"
              />
            </Col>
            <Col md={12} className='pt-3'>
              <div className="text-center">
                <Button color="primary" block disabled={isDisable} onClick={handalSave}>Save</Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Modal/Backdrop for loading state */}
      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Ambassador;
