import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography, Button, IconButton, ButtonGroup
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaTrash } from 'react-icons/fa'
import { AiOutlineFileAdd } from 'react-icons/ai';
import { IoCheckmarkDone } from "react-icons/io5";
import { RiMailCloseLine, RiMailCheckLine, RiMailCheckFill, RiMailCloseFill } from "react-icons/ri";
import Snackbar from '@mui/material/Snackbar';
import Swal from 'sweetalert2';

function ListPolls() {
    const navigate = useNavigate();
    const [poolsList, setpoolsList] = useState([]);
    const fetchList = async () => {
        const resist = await axios.get(`${process.env.REACT_APP_API_URL}/pool/list`)
        setpoolsList(resist.data.result);
    }
    const handalDel = async (row) => {
        if (window.confirm('Are you sure!') === true) {
            await axios.post(`${process.env.REACT_APP_API_URL}/pool/delete`, row)
            setTimeout(() => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully deleted",
                    showConfirmButton: false,
                    timer: 2000
                });
                fetchList();
            }, 1000);
        }
    }
    useEffect(() => {
        fetchList();
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/add-polls') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Polls list</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Question</TableCell>
                                    <TableCell >Option 1</TableCell>
                                    <TableCell>Option 2</TableCell>
                                    <TableCell>Option 3</TableCell>
                                    <TableCell>Option 4</TableCell>
                                    <TableCell align='center'>Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {poolsList.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell > {row.qus}</TableCell>
                                        <TableCell >{row.option1}</TableCell>
                                        <TableCell>{row.option2}</TableCell>
                                        <TableCell>{row.option3}</TableCell>
                                        <TableCell>{row.option4}</TableCell>
                                        <TableCell >

                                            <ButtonGroup>
                                                <IconButton color='error' onClick={() => { handalDel(row) }}>
                                                    <FaTrash />
                                                </IconButton>
                                                {/*<IconButton color='warning' onClick={() => { navigate('/dashboard/invite', { state: row }) }}>
                                                    <FaEdit />
                                                </IconButton> */}
                                                {/* <IconButton >
                                                    <IoCheckmarkDone />
                                                </IconButton> */}


                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
        </div>
    )
}

export default ListPolls