import { Card, TextField, Breadcrumbs, Typography, FormControl,
    Button

} from '@mui/material'
import { Col, Row, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'


function AnnouncementsAdd() {
    const [newAnnounce, setNewAnnounce] = useState({title:'',e_date:''})
    const [announceList, setAnnounceList] = useState([]);

    const addNew = async()=>{
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/announce/add`,newAnnounce)
    }
    const fetchList = async()=>{
        const fetData = await axios.get(`${process.env.REACT_APP_API_URL}/announce/list`)
        console.log(fetData)
        if(fetData.data){
            setAnnounceList(fetData.data.result)
        }
    }
    useEffect(()=>{
        fetchList();
    },[])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Announcement Add</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Announcement Add</h4>
                    <div className="row">
                        <div className='col-sm-12' >
                            <form>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Title" size='small'
                                        variant="outlined" type='text'
                                        InputLabelProps={{ shrink: true }}
                                        value={newAnnounce.title}
                                        onChange={(e)=>{ setNewAnnounce((prev)=>({...prev,title:e.target.value })) }}
                                    />
                                </FormControl>
                                
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Date" size='small'
                                        variant="outlined" type='date'
                                        InputLabelProps={{ shrink: true }}
                                        value={newAnnounce.e_date}
                                        onChange={(e)=>{ setNewAnnounce((prev)=>({...prev,e_date:e.target.value })) }}
                                    />
                                </FormControl>
                                
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Button variant="contained" onClick={addNew}>Add</Button>
                                </FormControl>
                            </form>
                        </div>
                    </div>
                </Card>
                <Card className='pt-5 pb-5 ps-5'>
                    <Row className='bg-white width-100'>
                        <Col md={12} className=''>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Title</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {announceList.length>0 &&
                                    <>
                                    {announceList.map((list,i)=>(
                                        <tr key={i}>
                                            <td>{i+1} </td>
                                            <td>{list.title} </td>
                                            <td>{list.date} </td>
                                            <td> </td>
                                        </tr>
                                    ))}
                                    </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>

    )
}

export default AnnouncementsAdd