import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography, IconButton, ButtonGroup
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Table } from 'reactstrap'
import axios from 'axios';

// import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';

function TokenPortfolio() {
    const [transfetList,setTransferLIst] = useState([]);
    const transferFetch = async()=>{
        const resData = await axios.get(`${process.env.REACT_APP_API_URL}/transfer/list`)
        if(resData.data){
            setTransferLIst(resData.data.result)
        }
        console.log(resData)
    }
    useEffect(()=>{
        transferFetch()
        
    },[])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Token Portfolio</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                    <h4>Total Tokens Available: <b>1,500 NGTG</b> </h4>
                    <h4>Last Updated: <b> {new Date().getDate()}/
                        {new Date().getMonth() + 1}  /{new Date().getFullYear()} </b> </h4>
                    <hr />
                    <Table striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Token Quantity</th>
                                <th>Issue Date</th>
                                <th>Release Date</th>
                                <th>Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                        {transfetList.length>0 &&
                                    <>
                                    {transfetList.map((list,i)=>(
                                        <tr key={i}>
                                            <td>{i+1} </td>
                                            <td>{list.num_token} </td>
                                            <td>{list.issue_date} </td>
                                            <td>{list.release_data} </td>
                                            <td>{list.note} </td>
                                            <td>

                                            </td>
                                        </tr>
                                    ))}
                                    </>
                                    }
                        </tbody>
                    </Table>
                </Card>

            </div>
        </div>

    )
}

export default TokenPortfolio