import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography, IconButton, ButtonGroup
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Alert } from 'reactstrap'
import { GrAnnounce } from "react-icons/gr";
import axios from 'axios';

function Announcements() {
    const [announceList, setAnnounceList] = useState([]);

    const fetchList = async()=>{
        const fetData = await axios.get(`${process.env.REACT_APP_API_URL}/announce/list`)
        console.log(fetData)
        if(fetData.data){
            setAnnounceList(fetData.data.result)
        }
    }
    useEffect(()=>{
        fetchList();
    },[])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Announcements</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                <h4>Announcements & Updates </h4>
                {announceList.length>0 &&
                <>
                {announceList.map((list,i)=>(
                    <Alert color="primary" key={i}>
                    <GrAnnounce /> {' '}
                    •	{list.date}: {list.title}

                </Alert>
                ))}
                </>
                }
                    
                </Card>
            </div>
        </div>
    )
}

export default Announcements