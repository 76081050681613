import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, TextField, Typography, FormControl, Button, Autocomplete
} from '@mui/material'

import { Link, useNavigate } from 'react-router-dom'
import { Table } from 'reactstrap'
import axios from 'axios';
const options = ['Top', 'Normal'];


function Support() {
    const [newSupport, setNewSupport] = useState({subject:'',message:''})
    const [supportList, setSupportList] = useState([]);
    const addNew = async()=>{
        const resDat = axios.post(`${process.env.REACT_APP_API_URL}/support/add`,newSupport)
    }

    const fetchSupport = async()=>{
        const resData = await axios.get(`${process.env.REACT_APP_API_URL}/support/list`)
        if(resData.data){
            setSupportList(resData.data.result)
        }
    }
    useEffect(()=>{
        fetchSupport();
    },[])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Support </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Create Ticket</h4>
                    <div className="row">
                        <div className='col-sm-12' >
                            <form>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Subject" size='small'
                                        variant="outlined" type='text'
                                        InputLabelProps={{ shrink: true }}
                                        value={newSupport.subject}
                                        onChange={(e)=>{ setNewSupport((prev)=>({...prev, subject: e.target.value})) }}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Message" size='small'
                                        variant="outlined" type='text'
                                        InputLabelProps={{ shrink: true }}
                                        value={newSupport.message}
                                        onChange={(e)=>{ setNewSupport((prev)=>({...prev, message: e.target.value})) }}
                                    />
                                </FormControl>
                                {/* <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        options={options} size='small'
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Priority" />}
                                    />
                                </FormControl> */}
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Attachment" size='small'
                                        variant="outlined" type='file'
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Button variant="contained" onClick={addNew}>Add</Button>
                                </FormControl>
                            </form>
                        </div>
                    </div>
                </Card>

                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                    <h4>Support Center </h4>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Ticket No</th>
                                <th>Details</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supportList.length>0 &&
                            <>
                            {supportList.map((list,i)=>(
                                <tr key={i}>
                                    <td>{i+1} </td>
                                    <td>{list.create_date} </td>
                                    <td>{list.id} </td>
                                    <td>{list.subject} </td>
                                    <td>pending </td>
                                </tr>
                            ))}
                            </>
                            }
                        </tbody>
                    </Table>
                </Card>
            </div>

        </div >
    )
}

export default Support