import React, { useEffect, useState } from 'react'
import logo from '../img/logo.png'
import { Link, useNavigate } from 'react-router-dom'


import imga11 from '../img/img11.png';
import img12 from '../img/img12.png';
// import img13 from '../img/img13.png';
// import img14 from '../img/img14.png';
import bitmart from '../img/Bitmart Logo 4 White.png';
import lbank from '../img/Lbank Logo 2.png'
import { FaArrowRightLong } from "react-icons/fa6";
import coinmarket from '../img/coin_market.png'
import coingk from '../img/coingk.png'
import CookieConsent from "react-cookie-consent";
import { Button } from 'reactstrap'
import imgOne from '../img/img1.jpeg';
import imgTwo from '../img/img2.jpeg';
import imgThree from '../img/img3.jpeg'
import TokenInfo from './TokenInfo';


function Main() {
    const [seeMore, setSeeMore] = useState(false);
    const navigate = useNavigate();
    const [bal,setBal] = useState(0)
    const getBal = (data)=>{
console.log(data)

//alert(data).

    }
    useEffect(()=>{
        if(localStorage.getItem('blanace')){
            setBal(localStorage.getItem('blanace'))
        }
        setInterval(() => {
            if(localStorage.getItem('blanace')){
                setBal(localStorage.getItem('blanace'))
            }
            //console.log(localStorage.getItem('blanace'))
        }, 1000);
    },[])
    return (
        <div>
            <CookieConsent
                overlay={true}
                location="bottom"
                buttonText="I understand"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                {/* This website uses cookies to enhance the user experience.{" "}
                <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
                <div className='scroll'>
                    <p>Our Privacy Policy defines how we collect, use,
                        and disclose personal information when you visit, browse or use our website. By accessing or using the Website, you agree to the terms of this Policy. If you do not agree with any part of this Policy,
                        you must not use the Website.</p>
                    {seeMore === false &&
                        <>
                            <span className='see-more' onClick={(e) => { setSeeMore(!seeMore) }}>See more..</span>
                        </>
                    }
                    {seeMore === true &&
                        <>
                            <ul>
                                <li>1. Information We Collect and use for Analytics </li>
                                <li>1.1 Personal Information:
                                    We may collect personal information that you voluntarily provide to us when you
                                    interact with the Website. This may include your name, email address, phone number,
                                    and any other information you choose to provide.
                                </li>
                                <li>
                                    1.2 Usage Information:
                                    We may automatically collect certain information when you visit the Website,
                                    such as your IP address, browser type, operating system, referring website, pages viewed,
                                    and the dates and times of your visits.
                                    This information is collected using cookies, web beacons, and similar technologies.
                                </li>
                                <li>
                                    2. Use of Information
                                </li>
                                <li>
                                    2.1 We may use the information we collect to:
                                    <br />
                                    – Provide, maintain, and improve the Website’s functionality.
                                    <br />
                                    – Respond to your inquiries and communicate with you about our services.
                                    <br />
                                    – Send you promotional materials or newsletters, subject to your consent where required by law.
                                    <br />
                                    – Personalize and enhance your experience on the Website.
                                    <br />
                                    – Monitor and analyze usage and trends to improve the Website and our services.
                                    <br />
                                    – Monitor and analyze usage and trends to improve the Website and our services.
                                    <br />
                                    – Protect the security and integrity of the Website and our users.
                                </li>
                                <li>
                                    3. Disclosure of Information
                                </li>
                                <li>
                                    3.1 We may share your personal information with:
                                    <br />
                                    – Service providers who assist us in operating the Website and providing our services.
                                    <br />
                                    – Third-party analytics providers to analyze usage and trends.
                                    <br />
                                    – Law enforcement authorities, government officials, or other third parties when required by law or in response to a legal process.
                                    <br />
                                    – Other third parties with your consent or at your direction.
                                </li>
                                <li>
                                    4. Data Security
                                </li>
                                <li>
                                    4.1 We take reasonable measures to protect the security of your personal information. However, no method of transmission over
                                    the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                                </li>
                                <li>
                                    5. Third-Party Links
                                </li>
                                <li>
                                    5.1 The Website may contain links to third-party websites. We are not responsible for the privacy practices or the content of such websites.
                                    We encourage you to read the privacy policies of those websites before providing any personal information.
                                </li>
                                <li>
                                    6. Children’s Privacy
                                </li>
                                <li>
                                    6.1 The Website is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently
                                    collected personal information from a child, we will take steps to delete it as soon as possible.
                                </li>
                                <li>
                                    7. Your Rights
                                </li>
                                <li>
                                    7.1 You may have certain rights regarding your personal information, including the right to access, correct, or delete the information we hold about you.
                                    To exercise your rights, please contact us using the information provided at the end of this Policy.
                                </li>
                                <li>
                                    8. Changes to the Policy
                                </li>
                                <li>
                                    8.1 We may update this Policy from time to time. Any changes will be effective immediately upon posting on the Website. Your continued use
                                    of the Website after any modifications to the Policy constitutes your acceptance of the revised terms.
                                </li>
                                <li>
                                    9. Contact Us
                                </li>
                                <li>
                                    9.1 If you have any questions, concerns, or requests regarding this Policy or the privacy practices of the Website, please contact us at support@cunninghammining.com or support@nuggettrap.com
                                    By using the Website, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy. If you do not agree to this Policy, please refrain from using the Website.
                                </li>
                            </ul>
                            {seeMore === true &&
                                <>
                                    <span className='see-more' onClick={(e) => { setSeeMore(!seeMore) }}>See less..</span>
                                </>
                            }
                        </>
                    }
                </div>
            </CookieConsent>

            <div className='welcome-sec'>
                <div className='text-atea-wel pt-5 pb-5 pb-5'>
                    <img src={logo} className='wel-img' alt='' />
                    {/* Welcome to Nugget Trap Gold RWA & Utility Token */}
                    <p className='wel-text '>welcome to <span>Nugget Trap Gold </span> RWA & Utility Token</p>
                    {/* <p className='text-light'> Seamlessly offer PAXG, now with zero on-chain transfer fees</p> */}

                    <div className='gold-price'>
                        <span className="gold">  Gold Price
                            <span className="text-success"> 3611.16 </span> CAD
                        </span>
                    </div>
                    <div className='gold-price'>
                        <span className="gold">  
                            <span className="text-success"> <TokenInfo blalace={getBal} />
                             {Number(bal)/100 } </span>
                            NGTG$$
                        </span>
                    </div>

                    <div>
                        <Link to="https://coinmarketcap.com/currencies/nugget-trap-gold-token/" target='_blank'>
                            <img src={coinmarket} alt='' className='img-a' /></Link>
                        <Link to="https://www.coingecko.com/en/coins/nugget-trap" target='_blank'>
                            <img src={coingk} alt='' className='img-a' />
                        </Link>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <div>
                <div className='container'>
                    <div className='pt-5'>
                        {/* <h4 className='text-center text-a'>The Future of Mining Excellence!</h4> */}
                        <h4 className='text-center text-a'>Fueling Mining's Digital Shift with Every Nugget!</h4>
                        <p className='pt-2 text1' style={{ color: '#454545' }}>
                            {/* We would like to welcome you to the Nugget Trap gold placer mine project, located in British Columbia,
                            Canada. Our team carefully selected the Nugget Trap Site after an exhaustive search of potential projects that
                            met three important characteristics. */}
                            We are thrilled to introduce you to the Nugget Trap Gold Placer Mine Project,
                            located in British Columbia, Canada. This project represent a Real World Asset (RWA)
                            Token and is complemented by its seamless use as a Utility Token for the Mining industry.
                            Our team meticulously delected the Nugget Trap site after an extensive evaluation to
                            ensure it ensure it met three key criteria
                        </p>
                        <div className='row pt-5 col-tex img-box'>
                            <div className='col-sm-4 img-cointer'>
                                <img src={imgOne} alt='' />
                                <div class="bottom-center">
                                    Digitization
                                </div>
                            </div>
                            <div className='col-sm-4 img-cointer'>
                                <img src={imgTwo} alt='' />
                                <div class="bottom-center">
                                    Tokenization
                                </div>
                            </div>
                            <div className='col-sm-4 img-cointer'>
                                <img src={imgThree} alt='' />
                                <div class="bottom-center">
                                    Real-World Assets
                                </div>
                            </div>
                        </div>

                        <div className='row pt-5 col-tex'>

                            <div className='col-sm-4'>
                                <p>
                                    First and foremost, our team wanted to identify a project located in a mining friendly Country and territory.
                                    Canada itself has historically been actively a pro-mining Country, possessing vast mineral resources,
                                    and equally importantly, knowledgeable mine personnel resources to draw upon for extraction.
                                    British Columbia’s gold mining history has been nothing short of legendary. With the Nugget Trap Placer Mine sitting
                                    inside British Columbia’s famed “Golden Triangle”, we feel the project geographically is in a tier 1 location.
                                </p>
                            </div>
                            <div className='col-sm-4'>
                                <p>
                                    The second desired characteristic for this project search was concentration of gold as well as placer shallow access.
                                    The Nugget Trap Mine has shown concentrations of gold in the 25 grams/metric ton of pay during the 25 pit assay testing.
                                    High concentrations of silver were also present in these various test pits, which is not an uncommon feature of the Golden
                                    Triangle area mines. Deep mining is not needed for high concentration recovery on the Nugget Trap Project, making it ideal for
                                    success in operational profitability. Samples were taken from surface down to four feet for our testing only.
                                    This shallow mining allows for the operational costs to be much lower than deep mining, as well as an expedited timeline for gold recovery.

                                </p>
                            </div>
                            <div className='col-sm-4'>
                                <p>
                                    In terms of desirability for gold mining, a proven area of high concentration, which has only been tested and proven but not
                                    commercially exploited, is extremely rare. The Nugget Trap Placer Project is one that fits such a description
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-11 pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6 pt-5'>
                            <h2 className='hh1'>The Nugget Trap Ecosystem</h2>
                            <p className='text-capital1'>NOW LISTED ON MAJOR CRYPTO EXCHANGES (CEX)!</p>
                            <div>
                                <Button color='primary' onClick={() => { navigate('/home/cex') }}>View more
                                    <FaArrowRightLong />
                                </Button>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='row pt-5 pb-5  cex-img'>
                                <div className='col-sm-6'>
                                    <img src={bitmart} alt='' />
                                </div>
                                <div className='col-sm-6'>
                                    <img src={lbank} alt='' />
                                </div>
                                <div className='col-sm-6'>
                                    <img src={imga11} alt='' />
                                </div>
                                <div className='col-sm-6 '>
                                    <img src={img12} alt='' />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* <div>
                <div className='container'>
                    <div className='container'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17144.749700800763!2d-122.83542024033999!3d53.9686088156825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53889736534f66a1%3A0x86d6607f6c26c06a!2sPidherny%20Recreation%20Site!5e1!3m2!1sen!2sin!4v1731425006225!5m2!1sen!2sin" width="100%" height="450"
                            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map'></iframe>

                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Main